<template>
  <v-container fluid fill-height class="bg">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" class="d-flex justify-center">
          <div>
            <brand></brand>
          </div>
        </v-col>
        <v-col md="6" cols="12" class="px-0">
          <v-card elevation="6">
            <profile-form
              title="Finalize seu cadastro"
              :show-disclaimer="true"
              @success="redirect"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ProfileForm from "../components/auth/ProfileForm.vue";
export default {
  components: { ProfileForm },
  methods: {
    redirect() {
      setTimeout(() => {
        this.$router.push("/");
      }, 1000);
    },
  },
};
</script>